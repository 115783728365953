import { v4 as uuid } from "uuid";

/**
 * Generate a UUID and remove the dashes
 * @returns {string} - A UUID without dashes
 * @example generateHexUuid() => "550e8400e29b41d4a716446655440000"
 */
export const generateHexUuid = (): string => {
	return uuid().replace(/-/g, "");
};
